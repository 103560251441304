<template>
  <div>
    <va-card class="mb-4">
      <va-card-title class="display-3 ml-3">{{
        $t("menu.applications")
      }}</va-card-title>
    </va-card>

    <va-card class="mb-4">
      <va-card-actions align="right">
        <va-button icon="fa-icon fa fa-plus" @click="showAddApp = true">{{
          $t("app_view.add_app")
        }}</va-button>
      </va-card-actions>

      <va-card-content>
        <div class="row">
          <div class="flex xs12 sm8 md6 lg5 xl4">
            <va-input
              v-model="term"
              :placeholder="$t('app_view.search_by')"
              clearable
            >
              <template #prependInner>
                <va-icon name="fa fa-search" />
              </template>
            </va-input>
          </div>

          <div
            class="flex xs12 sm4 md4 lg3 xl2 offset--md2 offset--lg4 offset--xl6"
          >
            <va-select
              v-model.number="perPage"
              :label="$t('tables_common.per_page')"
              :options="perPageOptions"
            />
          </div>
        </div>

        <va-data-table
          :items="filteredData"
          :columns="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :no-data-html="$t('tables_common.no_data')"
          :hoverable="true"
          :loading="loading"
          :striped="true"
        >
          <template #header(id)></template>

          <template #cell(enabled)="{ source: enabled }">
            <va-badge
              v-if="enabled"
              :text="$t('tables_common.active')"
              color="success"
            ></va-badge>
            <va-badge
              v-if="!enabled"
              :text="$t('tables_common.inactive')"
              color="danger"
            ></va-badge>
          </template>

          <template #cell(id)="{ source: id }">
            <va-popover
              :message="`${$t('tables_common.edit')}`"
              placement="top"
            >
              <va-button
                flat
                color="info"
                icon="fa fa-pencil"
                @click="editApp(id)"
              />
            </va-popover>

            <va-popover
              :message="`${$t('tables_common.regenerate')}`"
              placement="top"
            >
              <va-button
                flat
                color="warning"
                icon="fa fa-retweet"
                @click="regenApp(id)"
              />
            </va-popover>

            <va-popover
              :message="`${$t('tables_common.delete')}`"
              placement="top"
            >
              <va-button
                flat
                color="danger"
                icon="fa fa-trash"
                @click="removeApp(id)"
              />
            </va-popover>
          </template>
        </va-data-table>

        <div class="flex-center mt-3">
          <va-pagination
            v-if="pages > 1"
            v-model="currentPage"
            :pages="pages"
          />
        </div>
      </va-card-content>
    </va-card>

    <va-modal v-model="showAddApp" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("app_view.add_app") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="addForm.name"
              :label="$t('app_view.app_name')"
              :error="!!errors.addAppErrors.length"
              :error-messages="errors.addAppErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-checkbox
              v-model="addForm.enabled"
              :label="$t('common_forms.active')"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelAdd" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="addApplication" icon="fa-icon fa fa-plus">{{
          $t("common_forms.create")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal v-model="showEditApp" size="medium" :hideDefaultActions="true">
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("app_view.edit_app") }}
        </p>
      </template>

      <slot>
        <form>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-input
              class="flex xs12 md12"
              v-model="editedApp.name"
              :label="$t('app_view.app_name')"
              :error="!!errors.editAppErrors.length"
              :error-messages="errors.editAppErrors"
            />
          </div>
          <div class="row mb-3 ml-3 mr-3 formRow">
            <va-checkbox
              v-model="editedApp.enabled"
              :label="$t('common_forms.active')"
            />
          </div>
        </form>
      </slot>

      <template #footer>
        <va-button color="danger" @click="cancelEdit" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button @click="editApplication" icon="fa fa-pencil">{{
          $t("common_forms.edit")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showConfirmDelete"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('app_view.delete_app_confirm') + ' \'' + editedApp.name + '\'?'
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("app_view.delete_app") }}
        </p>
      </template>
      <template #footer>
        <va-button color="info" @click="cancelDelete" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="danger" @click="confirmDelete" icon="fa fa-trash">{{
          $t("common_forms.delete")
        }}</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="showConfirmRegen"
      size="medium"
      :hideDefaultActions="true"
      :message="
        $t('app_view.regen_app_confirm') +
        ' \'' +
        editedApp.name +
        '\'? ' +
        $t('app_view.regen_app_text')
      "
    >
      <template #header>
        <p class="display-3 mb-5 mt-3 text--center">
          {{ $t("app_view.regen_app") }}
        </p>
      </template>
      <template #footer>
        <va-button color="info" @click="cancelRegen" class="mr-3">
          {{ $t("common_forms.cancel") }}</va-button
        >
        <va-button color="warning" @click="confirmRegen" icon="fa fa-retweet">{{
          $t("common_forms.confirm")
        }}</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  data() {
    return {
      term: null,
      perPage: 10,
      perPageOptions: [10, 20, 50, 100],
      currentPage: 1,
      loading: true,
      tableData: [],
      showAddApp: false,
      showConfirmDelete: false,
      showConfirmRegen: false,
      showEditApp: false,
      addForm: {
        name: "",
        enabled: true,
      },
      errors: {
        addAppErrors: [],
        editAppErrors: [],
      },
      editedApp: {
        name: "",
        enabled: true,
        id: "",
      },
    };
  },

  created() {
    this.getApps();
    this.loading = false;
  },

  computed: {
    fields() {
      return [
        {
          key: "enabled",
          headerTitle: this.$t("app_view.active"),
          label: this.$t("app_view.active"),
          sortable: true,
        },
        {
          key: "name",
          headerTitle: this.$t("app_view.name"),
          label: this.$t("app_view.name"),
          sortField: "name",
          sortable: true,
        },
        {
          key: "clientId",
          headerTitle: this.$t("app_view.id"),
          label: this.$t("app_view.id"),
        },
        {
          key: "clientKey",
          headerTitle: this.$t("app_view.key"),
          label: this.$t("app_view.key"),
        },
        {
          key: "id",
          headerTitle: "",
          label: "",
          align: "right",
        },
      ];
    },

    filteredData() {
      if (!this.term || this.term.length < 1) {
        return this.tableData;
      }
      return this.tableData.filter((item) => {
        return item.name.toLowerCase().startsWith(this.term.toLowerCase());
      });
    },

    pages() {
      const pages =
        this.perPage && this.perPage !== 0
          ? Math.ceil(this.filteredData.length / this.perPage)
          : this.filteredData.length;
      if (pages < this.currentPage) {
        this.currentPage = pages === 0 ? 1 : pages;
      }
      return pages;
    },

    addFormReady() {
      return !this.errors.addAppErrors.length;
    },
  },

  methods: {
    search: debounce(function (term) {
      this.term = term;
    }, 400),

    addApplication() {
      // console.log('Form submitted' + this.addForm.name)
      this.errors.addAppErrors = this.addForm.name
        ? []
        : [this.$t("app_view.app_name_not_present")];
      if (!this.addFormReady) {
        // console.log('form not valid')
        return;
      }
      this.backend.addApp(this.addForm).then((d2) => {
        if (d2 !== false) {
          this.addForm.name = "";
          this.addForm.enabled = true;
          this.getApps();
          this.showAddApp = false;
        } else {
          this.launchToast(this.$t("app_view.error_create"), "warning");
        }
      });
    },

    cancelAdd() {
      // console.log('Clearing data on cancel dialog')
      this.addForm.name = "";
      this.addForm.enabled = true;
      this.errors.addAppErrors = [];
      this.showAddApp = false;
    },

    getApps() {
      this.backend.listApp().then((data) => {
        // console.log(data)
        if (data !== false) {
          this.tableData = data;
        } else {
          this.tableData = [];
          this.launchToast(this.$t("tables_common.error_data"), "danger");
        }
      });
    },

    launchToast(text, col) {
      this.$vaToast.init({
        message: text,
        position: "top-left",
        duration: 4000,
        fullWidth: false,
        color: col,
      });
    },

    removeApp(appId) {
      // console.log('Called remove')
      const app = this.tableData.find(({ id }) => id === appId);
      this.editedApp.id = app.id;
      this.editedApp.name = app.name;
      this.showConfirmDelete = true;
    },

    cancelDelete() {
      this.editedApp.id = "";
      this.editedApp.name = "";
      this.showConfirmDelete = false;
    },

    confirmDelete() {
      this.backend.removeApp(this.editedApp.id).then((d2) => {
        if (d2 !== false) {
          this.editedApp.id = "";
          this.editedApp.name = "";
          this.getApps();
          this.showConfirmDelete = false;
        } else {
          this.launchToast(this.$t("app_view.error_delete"), "danger");
        }
      });
    },

    regenApp(appId) {
      // console.log('Called regen')
      const app = this.tableData.find(({ id }) => id === appId);
      this.editedApp.id = app.id;
      this.editedApp.name = app.name;
      this.showConfirmRegen = true;
    },

    cancelRegen() {
      this.editedApp.id = "";
      this.editedApp.name = "";
      this.showConfirmRegen = false;
    },

    confirmRegen() {
      this.backend.regenApp(this.editedApp.id).then((d2) => {
        if (d2 !== false) {
          this.editedApp.id = "";
          this.editedApp.name = "";
          this.getApps();
          this.showConfirmRegen = false;
        } else {
          this.launchToast(this.$t("app_view.error_regen"), "warning");
        }
      });
    },

    editApp(appId) {
      // console.log('Called edit')
      const app = this.tableData.find(({ id }) => id === appId);
      this.editedApp.id = app.id;
      this.editedApp.name = app.name;
      this.editedApp.enabled = app.enabled;
      this.showEditApp = true;
    },

    cancelEdit() {
      this.editedApp.id = "";
      this.editedApp.name = "";
      this.editedApp.enabled = true;
      this.showEditApp = false;
    },

    editApplication() {
      this.errors.editAppErrors = this.editedApp.name
        ? []
        : [this.$t("app_view.app_name_not_present")];
      if (this.errors.editAppErrors.length) {
        // console.log('form not valid')
        return;
      }
      this.backend.editApp(this.editedApp.id, this.editedApp).then((d2) => {
        if (d2 !== false) {
          this.editedApp.id = "";
          this.editedApp.name = "";
          this.editedApp.enabled = true;
          this.getApps();
          this.showEditApp = false;
        } else {
          this.launchToast(this.$t("app_view.error_edit"), "warning");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.formRow {
  min-width: 20vw;
}
</style>
